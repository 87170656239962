<template>
    <div class="edit-nav">
        <div class="edit-nav__left">
            <el-avatar
                v-for="(item, index) in devices"
                :key="index"
                :src="item.equipmentIcon"
                @dragstart.native="dragstart($event, item)"
                draggable="true"
                @dragend.native="dragend"
                :title="'拖拽'+item.categoryName+'到地图'"
                ></el-avatar
            >
        </div>
        <div class="edit-nav__right">
            <el-button @click="handleAction('cancle')">返回</el-button>
            <el-button @click="handleAction('refresh')">刷新</el-button>
            <!-- <el-button type="primary" @click="handleAction('publish')">发布</el-button> -->
        </div>
    </div>
</template>powerGrid

<script>
import {equipmentTypes, equipmentTypeTrans} from './utils';
export default {
    data() {
        let types = ['mainSubstation', 'substation', 'ringMainUnit', 'towerPole'];
        let devices = types.map((it) => {
            let equipmentMainCategory = equipmentTypes[it];
            let categoryName = equipmentTypeTrans[equipmentMainCategory];
            return {
                categoryName,
                equipmentIcon: require(`@/assets/equipment/${equipmentMainCategory}.png`),
                equipmentMainCategory,
                //默认为待投产设备
                equipmentState: 'PUT_INTO_PRODUCTION',
                propertyType: '1',
            };
        });
        return {
            devices,
        };
    },
    methods: {
        handleAction(type) {
            this.$emit('action', type);
        },
        dragstart(e, item) {
            e.dataTransfer.setData('Data', JSON.stringify(item));
            this.offsetX = e.offsetX;
            this.offsetY = e.offsetY;
            // console.log(this.offsetX + '-' + this.offsetY);
        },
        /* eslint-disable space-before-function-paren */
        dragend(e) {
            e.dataTransfer.clearData();
        },
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/style/variables.less';
.edit-nav {
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    // background: #fff;
    // border-bottom: 1px solid #ebeef5;
    // margin-top: -10px;
    &__left {
        display: flex;
        align-items: center;
    }
    &__right {
        display: flex;
        align-items: center;
    }
}
/deep/ .el-avatar {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    width: 38px;
    height: 38px;
    margin-right: 5px;
    cursor: pointer;
    background: @color-primary;
}
</style>

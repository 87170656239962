<template>
    <div class="edit-wrap">
        <div class="edit-wrap__left">
            <device-design-drawing
                :id="id"
                ref="deviceDesignDrawing"
                :drawingData="drawingData"
            ></device-design-drawing>
        </div>
        <div class="edit-wrap__right">
            <map-header @action="handleHeadAction"></map-header>
            <device-legend-panel
                ref="DeviceLegendPanel"
                :allPoints="allPoints"
                :withHeader="true"
                @showPointsChange="handleShowPointsChange"
            ></device-legend-panel>
            <component
                :is="mapItemComponent"
                :gridId="id"
                :formData="mapItemInfo"
                :mapItemHash="mapItemHash"
                :drawingData="drawingData"
                ref="editForm"
                @coordsChange="handleCoordsChange"
                @reFetchMapData="handleReFetchMapData"
            ></component>
            <map-base
                ref="mapbase"
                :mapItemIndex="mapItemIndex"
                @itemClick="handleMapItemClick"
                @mapLayerClick="handleMapLayerClick"
                @pointDrag="handlePointDrag"
                @bmapRoam="handleBmapRoam"
                :lines="lines"
                :editable="true"
                :gridLines="gridLines"
                :points="showPoints"
            ></map-base>
        </div>
    </div>
</template>

<script>
import DeviceDesignDrawing from './DeviceDesignDrawing';
import MapBase from './MapBase';
import PanelMainSubstation from './DeviceInfoConfPanel/MainSubstation';
import PanelSubstation from './DeviceInfoConfPanel/Substation';
import PanelRingMainUnit from './DeviceInfoConfPanel/RingMainUnit';
import PanelTower from './DeviceInfoConfPanel/Tower';
import DeviceLegendPanel from './DeviceLegendPanel';
import MapHeader from './MapHeader';
import {equipmentTypeTrans} from './utils';
import mapCommon from './mapCommon';

export default {
    mixins: [mapCommon],
    components: {
        DeviceDesignDrawing,
        MapBase,
        MapHeader,
        PanelTower,
        PanelMainSubstation,
        PanelSubstation,
        PanelRingMainUnit,
        DeviceLegendPanel,
    },
    data() {
        const id = this.$route.params.id;
        return {
            id,
            editFormShow: false,
            mapItemInfo: {},
            mapItemIndex: undefined,
            drawingData: null,
            powerGridData: {},
            lines: [],
            gridLines: [],
            mapItemHash: Math.random().toString(16).slice(-5),
            mapItemComponent: '',
            showPoints: [],
            allPoints: [],
        };
    },
    watch: {
        $route(newV, oldV) {
            if (newV.params.id !== oldV.params.id) {
                this.handleHeadAction('refresh');
            }
        },
    },
    methods: {
        async handleHeadAction(type) {
            if (type === 'refresh') {
                this.getPowerGrids();
                this.getLayerData(this.$route.params.id);
                if (this.$refs.editForm) {
                    this.$refs.editForm.hide();
                }
            } else if (type === 'cancle') {
                let path = this.$route.path;
                this.$router.push(`/powerGridMonitor/index`);
                await this.$nextTick();
                this.$root.$emit('fe-framework:tab:close', path);
            }
        },
        handleCoordsChange(coords) {
            if (this.mapItemInfo) {
                this.mapItemInfo.coords = coords;
                let point = this.showPoints[this.mapItemIndex];
                point.coords = coords;
                this.$refs.mapbase.updateAll();
            }
        },
        // 地图编辑器消息适配器
        handleMapItemClick(type, index, data) {
            if (type === 'point') {
                let {equipmentMainCategory} = data;
                let trans = {
                    MAIN_SUBSTATION: 'PanelMainSubstation',
                    SUBSTATION: 'PanelSubstation',
                    RING_MAIN_UNIT: 'PanelRingMainUnit',
                    TOWER_POLE: 'PanelTower',
                };
                this.mapItemInfo = data;
                this.mapItemIndex = index;
                this.mapItemComponent = trans[equipmentMainCategory];
                this.mapItemHash = Math.random().toString(16).slice(-5);
                this.$nextTick(() => {
                    this.$refs.editForm.show();
                });
                this.showPopper(data);
            }
        },
        handlePointDrag(index, data) {
            if (index === this.mapItemIndex && this.mapItemInfo) {
                this.mapItemInfo.coords = data;
            }
        },

        // 设置配置介面事件
        deviceConfEvent(event, type, data) {
            console.log(data);
        },
        // TODO 变电站1
        getSubstationA() {
            return {};
        },
        getDrawingApplyDetail(id) {
            this.$client.getDrawingApplyDetail(id).then((res) => {
                this.drawingData = res.drawing;
            });
        },
        getPowerGrids(id) {
            if (!id) {
                id = this.$route.params.id;
            }
            this.$client.getPowerGrids(id).then((res) => {
                let {drawingApplyId} = res.data;
                this.powerGridData = res.data;
                this.getDrawingApplyDetail(drawingApplyId);
            });
        },
    },
    created() {
        this.getPowerGrids();
        this.getLayerData(this.$route.params.id);
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/style/variables.less';
.edit-wrap {
    height: 100%;
    display: flex;
    &__left {
        // flex-basis: 300px;
        height: 100%;
        // background: #fff;
        border-right: 1px solid @bg-diliver;
    }
    &__right {
        flex-grow: 1;
        position: relative;
        width: calc(100% - 300px);
        height: 100%;
        // background: #d6d6d6;
        display: flex;
        flex-direction: column;
    }
}
</style>

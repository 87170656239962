import { render, staticRenderFns } from "./Tower.vue?vue&type=template&id=353b2de6&scoped=true&"
import script from "./Tower.vue?vue&type=script&lang=js&"
export * from "./Tower.vue?vue&type=script&lang=js&"
import style0 from "./Tower.vue?vue&type=style&index=0&id=353b2de6&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "353b2de6",
  null
  
)

export default component.exports
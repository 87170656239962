<template>
    <transition name="el-fade-in">
        <div class="device-conf-panel" v-show="panelShow">
            <div class="ulbox">
                <el-form :model="baseValues" ref="form" :rules="baseRules" size="mini">
                    <el-row :gutter="24">
                        <el-col :span="24">
                            <el-form-item label="设备经度" prop="longitude">
                                <el-input-number
                                    @change="handleCoordsChange"
                                    v-model="baseValues.longitude"
                                    :step="0.01"
                                ></el-input-number>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="设备纬度" prop="latitude">
                                <el-input-number
                                    @change="handleCoordsChange"
                                    v-model="baseValues.latitude"
                                    :step="0.01"
                                ></el-input-number>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24"
                            ><el-form-item label="设备" prop="equipmentId">
                                <el-select
                                    v-model="baseValues.equipmentId"
                                    style="width: 100%"
                                    @change="handleEquipmentChange"
                                    :disabled="!isUnsaved"
                                >
                                    <el-option
                                        v-for="(item, index) in equipmentOptions"
                                        :key="index"
                                        :label="item.key"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24"
                            ><el-form-item label="关联图纸" prop="drawingId">
                                <el-select v-model="baseValues.drawingId" style="width: 100%">
                                    <el-option
                                        v-for="(item, index) in drawingOptions"
                                        :key="index"
                                        :label="item.key"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-collapse v-model="collapseNames">
                        <el-collapse-item :name="0" title="高压侧配置">
                            <div v-for="(item, index) in inputSlot" :key="index">
                                <div class="item-lable-line">{{ item.name }}</div>
                                <el-form-item
                                    label="进线接入点"
                                    :prop="`inputBoxFormList[${index}]`"
                                    :rules="inputRules"
                                >
                                    <el-select
                                        v-model="baseValues.inputBoxFormList[index]"
                                        style="width: 100%"
                                        @change="(value) => handleInputEquipmentChange(value, index)"
                                        :clearable="true"
                                    >
                                        <el-option
                                            v-for="(item, index) in inputOptions"
                                            :key="index"
                                            :label="item.key"
                                            :value="item.value"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="接入线缆">
                                    <el-input v-model="inputBoxWireName[index]" style="width: 100%" :disabled="true">
                                    </el-input>
                                </el-form-item>
                            </div>
                        </el-collapse-item>
                        <el-collapse-item :name="1" title="低压侧配置">
                            <div v-for="(item, index) in outputSlot" :key="index">
                                <el-form-item
                                    :label="item.name || '柜体出线备注'"
                                    :prop="`outputBoxFormList[${index}]`"
                                    :rules="outputRules"
                                >
                                    <el-select
                                        v-model="baseValues.outputBoxFormList[index]"
                                        style="width: 100%"
                                        :clearable="!outputBoxDeviceName[index]"
                                        :disabled="!!outputBoxDeviceName[index]"
                                    >
                                        <el-option
                                            v-for="item in outputOptions"
                                            :key="item.value"
                                            :label="item.key"
                                            :value="item.value"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="连接设备" v-if="outputBoxDeviceName[index]">
                                    <el-input v-model="outputBoxDeviceName[index]" style="width: 100%" :disabled="true">
                                    </el-input>
                                </el-form-item>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </el-form>
            </div>
            <div class="form-btn-group">
                <el-button size="mini" type="primary" @click="handleMove">移动</el-button>
                <el-button size="mini" @click="handleCancle">取消</el-button>
                <el-button size="mini" type="primary" @click="handleDelete">删除</el-button>
                <el-button size="mini" type="primary" @click="handleSave">保存</el-button>
            </div>
        </div>
    </transition>
</template>

<script>
import {genItems, pickItemByProp} from '@/utils/formItemUtil';
import {equipmentTypeTrans} from '../utils';
import panelCommon from './panelCommon';
export default {
    mixins: [panelCommon],
    data() {
        return {
            baseValues: {
                outputBoxFormList: [],
                inputBoxFormList: [],
                longitude: undefined,
                latitude: undefined,
                equipmentId: undefined,
                oLon: undefined, //原始经度，用于取消移动后恢复原位置
                oLat: undefined, //原始维度，用于取消移动后恢复原位置
            },
            baseRules: {
                longitude: [{required: true, message: '请填写经度', trigger: 'blur'}],
                latitude: [{required: true, message: '请填写纬度', trigger: 'blur'}],
                equipmentId: [{required: true, message: '请选择设备', trigger: 'blur'}],
            },
            inputSlot: [],
            outputSlot: [],
            inputBoxList: [],
            outputBoxList: [],
            panelShow: false,
            unusedDevices: [],
            inputBoxWireName: [],
            outputBoxDeviceName: [],
            collapseNames: [0, 1],
            inputRules: [
                {
                    validator: (rule, value, callback) => {
                        if (value && this.baseValues.inputBoxFormList.filter((it) => it === value).length > 1) {
                            callback(new Error('不能选择相同入线设备'));
                        } else {
                            callback();
                        }
                    },
                    trigger: 'change',
                },
            ],
            outputRules: [
                {
                    validator: (rule, value, callback) => {
                        if (value && this.baseValues.outputBoxFormList.filter((it) => it === value).length > 1) {
                            callback(new Error('不能选择相同出线设备'));
                        } else {
                            callback();
                        }
                    },
                    trigger: 'change',
                },
            ],
        };
    },
    computed: {
        inputOptions() {
            return this.inputBoxList.map((it) => {
                return {
                    key: `${it.joinBoxName}`,
                    value: it.gridWireId,
                };
            });
        },
        outputOptions() {
            return this.outputBoxList.map((it) => {
                return {
                    key: `${it.wireName}`,
                    value: it.wireId,
                };
            });
        },
        drawingOptions() {
            let drawingData = this._.cloneDeep(this.drawingData);
            let arr = [];
            for (let i in drawingData) {
                arr.push(...drawingData[i]);
            }
            return arr.map((it) => {
                return {
                    key: it.drawingName,
                    value: it.id,
                };
            });
        },
        equipmentOptions() {
            return this.unusedDevices.map((it) => {
                return {
                    key: it.equipmentName,
                    value: it.equipmentId,
                };
            });
        },
    },
    methods: {
        handleCoordsChange() {
            this.$emit('coordsChange', [this.baseValues.longitude, this.baseValues.latitude]);
        },
        async handleSave() {
            const valid = await this.validate(this.$refs.form);
            if (valid) {
                let data = this._.cloneDeep(this.baseValues);
                delete data.inputBoxWireName;
                data.gridId = this.formData.gridId || this.gridId;
                data.inputBoxFormList = this.inputSlot.map((it, index) => {
                    return {
                        gridWireId: data.inputBoxFormList[index] || null,
                        boxId: it.id,
                    };
                });
                data.outputBoxFormList = this.outputSlot.map((it, index) => {
                    return {
                        boxId: it.id,
                        wireId: data.outputBoxFormList[index] || null,
                    };
                });
                if (this.isUnsaved) {
                    this.$client.insertSubstationsPoint(data).then((res) => {
                        this.hide();
                        this.$emit('reFetchMapData');
                    });
                } else {
                    this.$client.updateSubstationsPoint(this.dataId, data).then((res) => {
                        this.hide();
                        this.$emit('reFetchMapData');
                    });
                }
            } else {
                this.$message({
                    message: '请检查输入数据',
                    type: 'error',
                });
            }
        },
        getDetail() {
            let result;
            if (!this.isUnsaved) {
                result = this.$client.getPowerGridsEquipmentsSubstations(this.dataId).then((res) => {
                    return res.data;
                });
            } else {
                result = Promise.resolve(null);
            }
            return result;
        },
        resetValue() {
            this.baseValues = {
                outputBoxFormList: [],
                inputBoxFormList: [],
                longitude: undefined,
                latitude: undefined,
                equipmentId: undefined,
            };
            this.inputSlot = [];
            this.outputSlot = [];
            this.inputBoxList = [];
            this.outputBoxList = [];
            this.unusedDevices = [];
            this.inputBoxWireName = [];
            this.outputBoxDeviceName = [];
        },
        async fetchData() {
            this.resetValue();
            let getUnused = this.$client.getPowerGridsSubstationsUnused().then((res) => {
                return res.data;
            });
            let getInput = this.$client
                .getEquipmentsInputBoxes(this.isUnsaved ? undefined : this.dataId)
                .then((res) => {
                    return res.data;
                });
            let getOutput = this.$client.getPowerGridsWiresUnused().then((res) => {
                return res.data;
            });
            let getDetail = this.getDetail();

            Promise.all([getUnused, getInput, getOutput, getDetail]).then(
                ([unusedDevices, inputBoxList, outputBoxList, detailData]) => {
                    // 还原已选项
                    if (detailData) {
                        unusedDevices.unshift(this._.cloneDeep(detailData));
                        // 排除占位对象
                        let usedInputBoxList = detailData.inputBoxList.filter((it) => it.gridWireId);
                        let usedOutputBoxList = detailData.outputBoxList.filter((it) => it.wireId);
                        // 添加到待选列表
                        inputBoxList.unshift(...this._.cloneDeep(usedInputBoxList));
                        outputBoxList.unshift(...this._.cloneDeep(usedOutputBoxList));
                        this.baseValues = {
                            drawingId: detailData.drawingId,
                            outputBoxFormList: detailData.outputBoxList.map((it) => it.wireId),
                            longitude: detailData.longitude,
                            latitude: detailData.latitude,
                            equipmentId: detailData.equipmentId,
                            inputBoxFormList: detailData.inputBoxList.map((it) => it.gridWireId),
                            oLat: detailData.latitude,
                            oLon: detailData.longitude,
                        };
                        this.inputBoxWireName = detailData.inputBoxList.map((it) => it.wireName);
                        this.outputBoxDeviceName = detailData.outputBoxList.map((it) => it.joinEquipmentName);
                    } else {
                        this.baseValues = {
                            outputBoxFormList: [],
                            inputBoxFormList: [],
                            longitude: this.formData.coords[0],
                            latitude: this.formData.coords[1],
                            equipmentId: undefined,
                        };
                    }
                    this.unusedDevices = unusedDevices;
                    this.inputBoxList = inputBoxList;
                    this.outputBoxList = outputBoxList;
                    // 还原已选项
                    if (detailData) {
                        this.handleEquipmentChange(detailData.equipmentId);
                    } else {
                        this.handleEquipmentChange();
                    }
                }
            );
        },
        handleInputEquipmentChange(value, index) {
            let item = this.inputBoxList.filter((it) => it.gridWireId === value)[0];
            if (item) {
                this.$set(this.inputBoxWireName, index, item.wireName);
            } else {
                this.$set(this.inputBoxWireName, index, undefined);
            }
        },
        handleEquipmentChange(value) {
            let item = this.unusedDevices.filter((it) => it.equipmentId === value)[0];
            if (item) {
                this.selectDevice = item;
                let {inputBoxList: inputSlot, outputBoxList: outputSlot} = item;
                this.inputSlot = this._.cloneDeep(inputSlot);
                this.outputSlot = this._.cloneDeep(outputSlot);
            }
        },
    },
};
</script>

<style lang="less" scoped>
@import url(./panel.less);
</style>

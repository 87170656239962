export default {
    props: {
        gridId: 0,
        mapItemHash: 0,
        formData: {
            type: Object,
            default: () => {}
        },
        drawingData: {
            type: Object,
            default: () => {}
        }
    },
    watch: {
        'formData.coords'(newv, oldv) {
            if (!this._.isEqual(newv, oldv)) {
                let [longitude, latitude] = newv;
                this.baseValues.longitude = longitude;
                this.baseValues.latitude = latitude;
            }
        },
        mapItemHash(newv, oldv) {
            if (newv !== oldv) {
                this.fetchData();
            }
        }
    },
    computed: {
        dataId() {
            return this.formData.id;
        },
        isUnsaved() {
            return /unsaved/.test(this.formData.id);
        }
    },

    methods: {
        handleMove() {
            this.baseValues.isMove = true;
        },
        handleCancle() {
            this.baseValues.isMove = false;
            //还原位置
            this.baseValues.longitude = this.baseValues.oLon;
            this.baseValues.latitude = this.baseValues.oLat;
            this.$emit('coordsChange', [this.baseValues.oLon, this.baseValues.oLat]);
            //隐藏右侧弹出
            this.hide();
        },
        handleDelete() {
            this.$confirm(`确认删除 [${this.formData.categoryName}-${this.formData.id}] ？`, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                if (this.isUnsaved) {
                    this.hide();
                    this.$emit('reFetchMapData');
                } else {
                    this.$client.deletePowerGridsequipments(this.dataId).then(res => {
                        console.log('delete -> res', res);
                        this.hide();
                        this.$emit('reFetchMapData');
                    });
                }
            });
        },
        show() {
            this.panelShow = true;
        },
        hide() {
            this.panelShow = false;
        },
        validate(form) {
            return new Promise(resolve => {
                if (!form) {
                    resolve(true);
                    return;
                }
                form.validate(valid => {
                    if (valid) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
            });
        },
        cancel() {
            this.panelShow = false;
        }
    },
    created() {
        this.fetchData();
    }
};

<template>
    <transition name="el-fade-in">
        <div class="device-conf-panel" v-show="panelShow">
            <div class="ulbox">
                <el-form :model="baseValues" ref="form" :rules="baseRules" size="mini">
                    <el-row :gutter="24">
                        <el-col :span="24">
                            <el-form-item label="设备经度" prop="longitude">
                                <el-input-number
                                    @change="handleCoordsChange"
                                    v-model="baseValues.longitude"
                                    :step="0.01"
                                ></el-input-number>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="设备纬度" prop="latitude">
                                <el-input-number
                                    @change="handleCoordsChange"
                                    v-model="baseValues.latitude"
                                    :step="0.01"
                                ></el-input-number>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24"
                        >
                            <el-form-item label="设备" prop="equipmentId">
                                <el-select
                                    v-model="baseValues.equipmentId"
                                    style="width: 100%"
                                    @change="handleEquipmentChange"
                                    :disabled="!isUnsaved"
                                >
                                    <el-option
                                        v-for="(item, index) in equipmentOptions"
                                        :key="index"
                                        :label="item.key"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="交叉跨越物">
                                <el-input disabled v-model="selectDevice.crossCrossing"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="设备地形">
                                <el-input disabled v-model="selectDevice.terrain"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-collapse v-model="collapseNames">
                        <el-collapse-item :name="index" v-for="(item, index) in this.baseValues.loops" :key="index">
                            <template slot="title"
                            >回路{{ index + 1 }}配置<i
                                class="header-icon el-icon-close icon-delete-loop"
                                title="删除"
                                @click="handleDeleteLoop(index, $event)"
                            ></i
                            ></template>
                            <!-- <div class="item-lable-line"></div> -->
                            <el-form-item
                                label="进线接入点"
                                :rules="inputDeviceRules"
                                :prop="`loops.${index}.inputGridWireId`"
                            >
                                <el-select
                                    v-model="item.inputGridWireId"
                                    style="width: 100%"
                                    @change="(value) => handleInputEquipmentChange(value, item, index)"
                                    :clearable="true"
                                >
                                    <el-option
                                        v-for="(item, index) in inputOptions"
                                        :key="index"
                                        :label="item.key"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="接入线缆" :prop="`loops.${index}.inputWireName`">
                                <el-input v-model="item.inputWireName" style="width: 100%" :disabled="true"></el-input>
                            </el-form-item>
                            <el-form-item
                                label="开关配置"
                                :prop="`loops.${index}.towerPoleSwitchId`"
                                :rules="switchRules"
                            >
                                <el-select v-model="item.towerPoleSwitchId" style="width: 100%" :clearable="true">
                                    <el-option
                                        v-for="(item, index) in switchesInBoxList"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item
                                label="接入方式"
                                :rules="inputMethodRule"
                                :prop="`loops.${index}.joinTypeEnum`"
                            >
                                <el-select v-model="item.joinTypeEnum" style="width: 100%" :clearable="true"
                                           @change="handleJoinTypeChange(item)">
                                    <el-option
                                        v-for="(item, index) in joinTypeEnumOption"
                                        :key="index"
                                        :label="item.key"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <div v-if="item.showBtn">
                                <div v-for="(c,cI) in item.children" :key="cI">
                                    <el-form-item label="子回路" style="padding-top: 10px">
                                        <i
                                            class="header-icon el-icon-close icon-delete-loop"
                                            style="color: white"
                                            title="删除"
                                            @click="handleDeleteLoopChild(index, cI, $event)"
                                        ></i>
                                    </el-form-item>
                                    <el-form-item
                                        label="出线线缆"
                                        :rules="outputDeviceRules"
                                    >
                                        <el-select
                                            v-model="c.outWireId"
                                            style="width: 100%"
                                            :clearable="!c.outputJoinEquipmentName"
                                            :disabled="!!c.outputJoinEquipmentName"
                                            @change="$forceUpdate()"
                                        >
                                            <el-option
                                                v-for="item in outputOptions"
                                                :key="item.value"
                                                :label="item.key"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item
                                        label="出线开关配置"
                                        :rules="switchRules"
                                    >
                                        <el-select v-model="c.towerPoleSwitchId" style="width: 100%"
                                                   :clearable="true" @change="$forceUpdate()">
                                            <el-option
                                                v-for="(item, index) in switchesBoxList"
                                                :key="index"
                                                :label="item.name"
                                                :value="item.id"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <!--<el-form-item
                                        label="连接设备"
                                        v-if="item.outputJoinEquipmentName"
                                        :prop="`children.${cI}.outputJoinEquipmentName`"
                                    >
                                        <el-input v-model="c.outputJoinEquipmentName" style="width: 100%"
                                                  :disabled="true" @change="$forceUpdate()">
                                        </el-input>
                                    </el-form-item>-->
                                </div>
                                <el-form-item v-if="item.showBtn">
                                    <el-button type="primary" style="width: 100%" @click="handleAddCircuitChild(item)">添加线缆
                                    </el-button>
                                </el-form-item>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </el-form>
                <div class="form-btn-add-wrap">
                    <div class="form-btn-add-wrap__btn" @click="handleAddLoop">
                        <div class="form-btn-add-wrap__icon"><i class="el-icon-plus"/></div>
                        <div class="form-btn-add-wrap__text">增加回路</div>
                    </div>
                </div>
            </div>
            <div class="form-btn-group">
                <el-button size="mini" type="primary" @click="handleMove">移动</el-button>
                <el-button size="mini" @click="handleCancle">取消</el-button>
                <el-button size="mini" type="primary" @click="handleDelete">删除</el-button>
                <el-button size="mini" type="primary" @click="handleSave">保存</el-button>
            </div>
        </div>
    </transition>
</template>

<script>
import panelCommon from './panelCommon';

export default {
    mixins: [panelCommon],
    data() {
        return {
            selectDevice: {},
            baseValues: {
                loops: [{}],
                longitude: undefined,
                latitude: undefined,
                equipmentId: undefined,
                oLon: undefined, //原始经度，用于取消移动后恢复原位置
                oLat: undefined, //原始维度，用于取消移动后恢复原位置
            },
            baseRules: {
                longitude: [{required: true, message: '请填写经度', trigger: 'blur'}],
                latitude: [{required: true, message: '请填写纬度', trigger: 'blur'}],
                equipmentId: [{required: true, message: '请选择设备', trigger: 'blur'}]
            },
            showAddWire: false,
            inputBoxList: [],
            outputBoxList: [],
            panelShow: false,
            unusedDevices: [],
            inputBoxSelect: [],
            collapseNames: [],
            switchesInBoxList: [],
            switchesBoxList: [],
            joinTypeEnumOption: [
                {
                    key: '连接',
                    value: 'CROSS'
                },
                {
                    key: '架空',
                    value: 'OVER'
                }
            ],
            inputDeviceRules: [
                {required: true, message: '请选择进线接入点', trigger: 'change'},
                {
                    validator: (rule, value, callback) => {
                        let ids = this.baseValues.loops.map((it) => it.inputGridWireId).filter((it) => it);
                        if (ids.length !== this._.uniq(ids).length) {
                            callback(new Error('不能选择相同进线接入点'));
                        } else {
                            callback();
                        }
                    },
                    trigger: 'change'
                }
            ],
            inputMethodRule: [
                {required: true, message: '请选择接入方式', trigger: 'change'},
                /*{
                    validator: (rule, value, callback) => {
                        let {fullField} = rule;
                        let index = /[\d]+/.exec(fullField)[0];
                        let item = this.baseValues.loops[index];
                        if (item) {
                            if (value === 'CROSS' && item.inputWireId && item.inputWireId === item.outWireId) {
                                callback(new Error('连接时入线线缆和出线线缆不能相同'));
                            }
                        }
                        callback();
                    },
                    trigger: 'change'
                }*/
            ],
            outputDeviceRules: [
                /*{
                    validator: (rule, value, callback) => {
                        let ids = this.baseValues.loops.map((it) => it.outWireId).filter((it) => it);
                        if (ids.length !== this._.uniq(ids).length) {
                            callback(new Error('不能选择相同出线设备'));
                        } else {
                            callback();
                        }
                    },
                    trigger: 'change'
                },
                {
                    validator: (rule, value, callback) => {
                        let {fullField} = rule;
                        let index = /[\d]+/.exec(fullField)[0];
                        index = Number(index);
                        let item = this.baseValues.loops[index];
                        let otherLoop = this.baseValues.loops.filter((it, ix) => ix !== index);
                        let matchItem = otherLoop.filter((it) => it.inputWireId === item.outWireId)[0];
                        if (matchItem) {
                            callback(new Error('出线线缆不能为其他回路的入线线缆'));
                        } else {
                            callback();
                        }
                    },
                    trigger: 'change'
                }*/
            ],
            switchRules: [
                {
                    validator: (rule, value, callback) => {
                        let ids = this.baseValues.loops.map((it) => it.towerPoleSwitchId).filter((it) => it);
                        if (ids.length !== this._.uniq(ids).length) {
                            callback(new Error('不能选择相同开关'));
                        } else {
                            callback();
                        }
                    },
                    trigger: 'change'
                }
            ]
        };
    },
    computed: {
        inputOptions() {
            return this.inputBoxList.map((it) => {
                return {
                    key: `${it.joinBoxName}`,
                    value: it.gridWireId
                };
            });
        },
        outputOptions() {
            let selectInputOption = this.inputBoxSelect
                .filter((it) => it)
                .map((it) => {
                    return {
                        gridId: it.gridWireId,
                        wireId: it.wireId,
                        wireName: it.wireName
                    };
                });
            let outOption = [...this.outputBoxList, ...selectInputOption];
            //根据wireId去重
            outOption = this._.uniqBy(outOption, 'wireId');
            outOption = outOption.map((it) => {
                return {
                    key: `${it.wireName}`,
                    value: it.wireId
                };
            });
            return outOption;
        },
        equipmentOptions() {
            return this.unusedDevices.map((it) => {
                return {
                    key: it.equipmentName,
                    value: it.equipmentId
                };
            });
        }
    },
    methods: {
        handleCoordsChange() {
            this.$emit('coordsChange', [this.baseValues.longitude, this.baseValues.latitude]);
        },
        async handleSave() {
            const valid = await this.validate(this.$refs.form);
            if (valid) {
                let data = this._.cloneDeep(this.baseValues);
                data.gridId = this.formData.gridId || this.gridId;
                data.loops = data.loops.map((it) => this._.omit(it, ['inputWireId', 'inputWireName']));
                if (this.isUnsaved) {
                    this.$client.insertTowerPolesPoint(data).then((res) => {
                        this.hide();
                        this.$emit('reFetchMapData');
                    });
                } else {
                    this.$client.updateTowerPolesPoint(this.dataId, data).then((res) => {
                        this.hide();
                        this.$emit('reFetchMapData');
                    });
                }
            } else {
                this.$message({
                    message: '请检查输入数据',
                    type: 'error'
                });
            }
        },
        handleDeleteLoop(value, e) {
            e.stopPropagation();
            let item = this.baseValues.loops[value];
            if (item && item.outputJoinEquipmentName) {
                this.$message.error('当前回路已连接出线设备，不能删除');
                return;
            }
            this.$confirm(`确认删除 [ 回路${value + 1}配置 ] ？`, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let index = value;
                this.baseValues.loops.splice(index, 1);
                index = this.collapseNames.findIndex((it) => it === value);
                if (index !== -1) {
                    this.collapseNames.pop();
                }
            });
        },
        handleDeleteLoopChild(value, cI, e) {
            e.stopPropagation();
            let item = this.baseValues.loops[value].children[cI];
            if (item && item.outputJoinEquipmentName) {
                this.$message.error('当前回路已连接出线设备，不能删除');
                return;
            }
            this.$confirm(`确认删除 [ 子回路${cI + 1}配置 ] ？`, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let index = cI;
                this.baseValues.loops[value].children.splice(index, 1);
            });
        },
        getDetail() {
            let result;
            if (!this.isUnsaved) {
                result = this.$client.getPowerGridsEquipmentsTowerPoles(this.dataId).then((res) => {
                    let data = this.initChildData(res.data);
                    return data;
                });
            } else {
                result = Promise.resolve(null);
            }
            return result;
        },
        getSwitch() {
            //查询可选开关：1、新建时返回未使用的开关 2、修改时返回未使用+该杆塔正在使用的开关
            let result = this.$client.getPowerGridsTowerPoleSwitchesUnused().then((res) => {
                let data = this.initChildData(res.data);
                return data;
            });
            return result;
        },
        resetValue() {
            this.baseValues = {
                loops: [{}],
                longitude: undefined,
                latitude: undefined,
                equipmentId: undefined
            };
            this.switchesInBoxList = [];
            this.switchesBoxList = [];
            this.unusedDevices = [];
            this.inputBoxList = [];
            this.outputBoxList = [];
            this.collapseNames = [];
            this.inputBoxSelect = [];
        },
        async fetchData() {
            this.resetValue();
            let getUnused = this.$client.getPowerGridsTowerPolesUnused().then((res) => {
                return res.data;
            });
            let getInput = this.$client
                .getEquipmentsInputBoxes(this.isUnsaved ? undefined : this.dataId)
                .then((res) => {
                    return res.data;
                });
            let getOutput = this.$client.getPowerGridsWiresUnused().then((res) => {
                return res.data;
            });
            let getSwitchesIn = this.getSwitch();
            let getSwitches = this.getSwitch();
            let getDetail = this.getDetail();

            Promise.all([getUnused, getInput, getOutput, getSwitchesIn, getSwitches, getDetail]).then(
                ([unusedDevices, inputBoxList, outputBoxList, switchesInBoxList, switchesBoxList, detailData]) => {
                    // 还原已选项
                    let inputBoxSelect = [];
                    if (detailData) {
                        unusedDevices.unshift(this._.cloneDeep(detailData));
                        // 添加到待选列表
                        let loops = detailData.loops.map((it) => {
                            if (it.switchId) {
                                switchesInBoxList.unshift({id: it.switchId, name: it.switchName});
                            }
                            if (it.children && it.children.length) {
                                it.children.forEach(function(c) {
                                    if (c.switchId) {
                                        switchesBoxList.unshift({id: c.switchId, name: c.switchName});
                                    }
                                    if (c.outputGridWireId) {
                                        outputBoxList.unshift({
                                            gridId: c.outputGridWireId,
                                            wireId: c.outputWireId,
                                            wireName: c.outputWireName //outputJoinEquipmentName
                                        });
                                    }
                                });
                            }
                            inputBoxList.unshift({
                                gridWireId: it.inputGridWireId,
                                joinBoxName: it.inputJoinBoxName,
                                wireName: it.inputWireName,
                                wireId: it.inputWireId
                            });
                            inputBoxSelect.push({
                                gridWireId: it.inputGridWireId,
                                joinBoxName: it.inputJoinBoxName,
                                wireName: it.inputWireName,
                                wireId: it.inputWireId
                            });
                            if (it.outputGridWireId) {
                                outputBoxList.unshift({
                                    gridId: it.outputGridWireId,
                                    wireId: it.outputWireId,
                                    wireName: it.outputWireName //outputJoinEquipmentName
                                });
                            }
                            if (it.joinTypeEnum === 'CROSS') {
                                it.showBtn = true;
                            }
                            return {
                                inputGridWireId: it.inputGridWireId,
                                inputWireName: it.inputWireName,
                                inputWireId: it.inputWireId,
                                joinTypeEnum: it.joinTypeEnum,
                                // outWireId: it.outputWireId,
                                towerPoleSwitchId: it.switchId,
                                outputJoinEquipmentName: it.outputJoinEquipmentName,
                                showBtn: it.showBtn,
                                children: it.children
                            };
                        });
                        this.baseValues = {
                            longitude: detailData.longitude,
                            latitude: detailData.latitude,
                            equipmentId: detailData.equipmentId,
                            loops,
                            oLat: detailData.latitude,
                            oLon: detailData.longitude,
                        };
                        this.collapseNames = detailData.loops.map((it, index) => index);
                        // this.inputBoxSelect = detailData.inputBoxList.map((it) => it.wireName);
                    } else {
                        this.baseValues = {
                            loops: [{}],
                            longitude: this.formData.coords[0],
                            latitude: this.formData.coords[1],
                            equipmentId: undefined
                        };
                        this.collapseNames = [0];
                    }
                    this.inputBoxSelect = inputBoxSelect;
                    this.switchesInBoxList = switchesInBoxList;
                    this.switchesBoxList = switchesBoxList;
                    this.unusedDevices = unusedDevices;
                    this.inputBoxList = inputBoxList;
                    this.outputBoxList = outputBoxList;
                    // 还原已选项
                    if (detailData) {
                        this.handleEquipmentChange(detailData.equipmentId);
                    } else {
                        this.handleEquipmentChange();
                    }
                }
            );
        },
        handleAddLoop() {
            this.baseValues.loops.push({});
            this.collapseNames.push(this.baseValues.loops.length - 1);
        },
        handleInputEquipmentChange(value, item, index) {
            let equipment = this.inputBoxList.filter((it) => it.gridWireId === value)[0];
            if (equipment) {
                this.$set(item, 'inputWireName', equipment.wireName);
                this.$set(item, 'inputWireId', equipment.wireId);
                this.$set(this.inputBoxSelect, index, this._.cloneDeep(equipment));
            } else {
                this.$set(item, 'inputWireName', undefined);
                this.$set(item, 'inputWireId', undefined);
                this.$set(this.inputBoxSelect, index, undefined);
            }
        },
        handleEquipmentChange(value) {
            let item = this.unusedDevices.filter((it) => it.equipmentId === value)[0];
            if (item) {
                this.selectDevice = item;
            }
        },
        handleJoinTypeChange(item) {
            item.showBtn = false;
            if (item && item.joinTypeEnum === 'CROSS') {
                item.showBtn = true;
                if (!item.children || item.children.length === 0) {
                    item.children = [];
                    let child = this.initCircuitChild();
                    item.children.push(child);
                }
            }
        },
        handleAddCircuitChild(item) {
            if (!item.children) {
                item.children = [];
            }
            let child = this.initCircuitChild();
            item.children.push(child);
            this.refreshChild();
        },
        //初始化子回路
        initCircuitChild() {
            let child = {
                inputGridWireId: '',
                inputWireName: '',
                inputWireId: '',
                joinTypeEnum: 'CROSS',
                outWireId: '',
                towerPoleSwitchId: '',
                outputJoinEquipmentName: '',
            };
            return child;
        },
        //刷新子节点
        refreshChild() {
            this.handleAddLoop();
            this.baseValues.loops.pop();
            this.collapseNames.pop();
        },
        //半途接手，不清楚为何详情回填传过来的是PowerGridTowerPoleVO.CircuitVO,编辑提交是给过去的是TowerPoleForm，关键是同个字段在两个类的对应属性名也不同！！！！！
        // 故用此方法初始化详情回填属性名，以便编辑提交
        initChildData(data) {
            if (data && data.loops && data.loops.length) {
                data.loops.forEach(function(item) {
                    if (item.children && item.children.length) {
                        item.children.forEach(function(child) {
                            child.outWireId = child.outputWireId;
                            child.towerPoleSwitchId = child.switchId;
                        });
                    }
                });
            }
            return data;
        },
    },
};
</script>

<style lang="less" scoped>
    @import url(./panel.less);

    .ulbox {
        // height: calc(100% - 108px);
    }

    .form-btn-add-wrap {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 100px;

        &__btn {
            width: 70px;
            height: 70px;
            border-radius: 40px;
            background: #2a82e4;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #fff;
            cursor: pointer;
        }

        &__icon {
            font-size: 24px;
        }

        &__text {
            font-size: 13px;
        }
    }

    .icon-delete-loop {
        margin-left: 60%;
        cursor: pointer;
        font-weight: bolder;

        &:hover {
            color: #235eff;
        }
    }
</style>

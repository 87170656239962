<template>
    <!-- 设备设计图 -->
    <div class="monitor-drawing">
        <el-menu-collapse-transition>
            <el-collapse
                accordion
                v-model="activeName"
                class="monitor-drawing__collapse horizontal-collapse-transition"
                v-show="!collapse"
            >
                <el-collapse-item v-for="(item, index) in picData" :key="index" :name="index">
                    <template slot="title">{{ drawingTypeTrans[index] }} ({{ item.length }})</template>
                    <div v-for="(pic, count) in item" :key="count">
                        <el-image
                            @click="drawView(pic)"
                            style="width: 100%; height: auto; cursor: pointer"
                            :src="getPreviewUrl(pic)"
                            fit="contain"
                        ></el-image>
                        <div class="el-collapse-item__content-txt" @click="drawView(pic)">{{ pic.drawingName }}</div>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </el-menu-collapse-transition>
        <div class="verticality-collapse-trigger" @click="handleToggleCollpase">
            <i :class="collapse ? 'el-icon-arrow-right' : 'el-icon-arrow-left'"></i>
        </div>

        <el-dialog
            width="400px"
            :title="dialog.title"
            v-if="dialog.visible"
            :visible.sync="dialog.visible"
            :modal-append-to-body="true"
            :close-on-click-modal="true"
            :append-to-body="true"
            :show-close="true"
        >
            <img :src="dialog.url" style="width: 100%; height: auto; cursor: pointer" @click="showPdf(dialog.pic)" />
            <span slot="footer" class="dialog-footer">
                <el-button @click="showPdf(dialog.pic)" size="mini" type="primary">预览</el-button>
                <el-button @click="downloadPdf(dialog.pic)" size="mini" type="primary">下载</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {previewPdf, fileDownload} from '@/utils';
import ElMenuCollapseTransition from './ElMenuCollapseTransition.js';
export default {
    components: {
        ElMenuCollapseTransition,
    },
    props: {
        id: {
            type: [String, Number],
            default: '0',
        },
        drawingData: {},
    },
    watch: {
        picData(newV, oldV) {
            if (newV !== oldV) {
                let name;
                for (let i in newV) {
                    let item = newV[i];
                    if (item.length) {
                        name = i;
                        break;
                    }
                }
                if (!name) {
                    name = 'drawing30';
                }
                this.activeName = name;
            }
        },
    },
    computed: {
        picData() {
            let drawingData = this.drawingData || {};
            return {
                drawing10: drawingData.drawing10 || [],
                drawing20: drawingData.drawing20 || [],
                drawing30: drawingData.drawing30 || [],
            };
        },
    },
    data() {
        return {
            loading: false,
            activeName: 'drawing30',
            collapse: false,
            drawingTypeTrans: {
                drawing10: '开关站单线图',
                drawing20: '开关站主线图',
                drawing30: '变电站主线图',
            },
            dialog: {
                title: '',
                visible: false,
                url: '',
                pic: {},
            },
        };
    },
    methods: {
        handleToggleCollpase() {
            this.collapse = !this.collapse;
        },
        getPreviewUrl(pic) {
            let url = `${this.$client.serverUrl}files/${pic.ossId}/thumbnail?token=${this.$store.state.token}`;
            return url;
        },
        getDownloadUrl(pic) {
            let url = this.axios.defaults.baseURL + '/files/' + pic.ossId + '?token=' + this.$store.state.token;
            return url;
        },
        drawView(pic) {
            this.dialog = {
                title: pic.drawingName,
                visible: true,
                url: this.getPreviewUrl(pic),
                pic: this._.cloneDeep(pic),
            };
        },
        downloadPdf(pic) {
            fileDownload(this.getDownloadUrl(pic));
        },
        showPdf(pic) {
            previewPdf(this.getDownloadUrl(pic), pic.drawingName + '  ' + pic.drawingCode);
        },
    },
    created() {
        // this.initFormValue();
    },
};
</script>

<style lang="less" scoped>
.monitor-drawing {
    // margin-top: -10px;
    // margin-left: -10px;
    height: calc(100%);
    display: flex;
    position: relative;
    &__collapse {
        width: 300px;
        overflow-y: auto;
        height: 100%;
        &_hide {
            display: none;
        }
    }

    /deep/ .el-collapse-item {
        &__header {
            // color: #303133;
            // border-bottom: 1px solid #ebeef5;
            padding: 0 0 0 15px;
            font-weight: bold;
        }
        &__content {
            // color: #303133;
            padding: 0 15px;
        }
        &__content-txt {
            text-align: center;
            font-size: 12px;
            // color: #868686;
            cursor: pointer;
        }
    }
}
</style>
